import request from "@/utils/request";

// 获取部门列表（一级展示）
export function getList(params) {
  return request({
    url: "/department/getList",
    method: "get",
    params: params,
  });
}
