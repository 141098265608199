<!--  -->
<template>
  <div class="order-metting-ing">
    <!-- <TopWrapper
      :topObj="{
        bgColor: '#fff',
        title: '预约会议室',
        txtColor: '#333',
        isBack: true,
        isMsg: false,
      }"
    /> -->
    <div class="search-date">
      <p class="label">预定日期</p>
      <p class="picker-box">
        <van-cell :value="date" @click="showCalendar = true">
          <template #icon>
            <van-icon name="calendar-o" color="#999" :size="23" />
          </template>

          <template #right-icon>
            <van-icon name="arrow-down" color="#999" :size="16" />
          </template>
        </van-cell>
        <van-calendar v-model="showCalendar" @confirm="onConfirm" />
      </p>
    </div>
    <div class="main-panel">
      <div class="label">可预定会议室</div>
      <ul class="list-box">
        <li class="card-item" v-for="(item, index) in meetingList" :key="index">
          <p class="name">{{ item.mrName }}</p>
          <p class="equipments">{{ item.equipments }}</p>
          <p class="area-nums">
            <span class="area">{{ item.address }}&nbsp;/&nbsp;</span>
            <span class="num"
              ><van-icon name="friends-o" />{{ item.peopleNumber }}</span
            >
          </p>
          <ul class="bar-ul">
            <li
              v-for="(subItem, subIndex) in item.timeDetail"
              :key="'' + index + subIndex"
              class="bar-li"
            >
              <div
                :class="{
                  bar: true,
                  gray: 0 == subItem.status,
                  darkGray: 2 == subItem.status,
                  blue: 3 == subItem.status,
                }"
                @click="handelReservation(item, index, subItem, subIndex)"
              ></div>
              <div class="num">{{ subItem.number }}</div>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <van-popup
      v-model="show"
      v-if="show"
      get-container="#app"
      position="bottom"
      :style="{ height: 'auto' }"
    >
      <div class="container">
        <div class="title">{{ reserve.mrName }}</div>
        <div class="item">
          <div class="sub-title">会议主题<span class="red">*</span></div>
          <input
            type="text"
            class="input"
            v-model="form.mrSubject"
            maxlength="20"
          />
        </div>
        <div class="item">
          <div class="sub-title">会议描述</div>
          <input
            type="text"
            class="input"
            v-model="form.mrDesc"
            maxlength="500"
          />
        </div>
        <div class="item">
          <div class="sub-title">是否发送通知</div>
          <van-switch
            v-model="form.isNotify"
            size="24"
            active-value="1"
            inactive-value="0"
          />
        </div>
        <div class="item" @click="goPickStaff">
          <div class="sub-title">参会人员<span class="red">*</span></div>
          <div class="text">{{ checkNodes | formatCheck }}</div>
        </div>
        <div class="item time">
          <div class="sub-title">时间<span class="red">*</span></div>
          <div class="text">{{ checkTime | formatCheckTime(reserve) }}</div>
          <el-checkbox-group
            v-model="checkTime"
            @change="changeCheck"
            class="checkbox-group"
          >
            <div
              class="checkbox"
              v-for="(item, index) in reserve.timeDetail"
              :key="index"
            >
              <template v-if="0 == item.status">
                <el-checkbox disabled class="check">
                  {{ `${item.beginTime}-${item.time}` }}
                </el-checkbox>
                <div class="tip">已超时</div>
              </template>
              <template v-else-if="2 == item.status">
                <el-checkbox disabled class="check">
                  {{ `${item.beginTime}-${item.time}` }}
                </el-checkbox>
                <div class="tip">已预约</div>
              </template>
              <template v-else-if="3 == item.status">
                <el-checkbox disabled class="check">
                  {{ `${item.beginTime}-${item.time}` }}
                </el-checkbox>
                <div class="tip">已预约</div>
              </template>
              <template v-else>
                <el-checkbox :label="item.index" class="check">
                  {{ `${item.beginTime}-${item.time}` }}
                </el-checkbox>
              </template>
            </div>
          </el-checkbox-group>
        </div>
        <div class="bottom-buttons">
          <div class="button cancel" @click="cancel">取消</div>
          <div class="button primary" @click="submit">确定</div>
        </div>
      </div>
    </van-popup>
    <van-popup
      v-model="showPerson"
      get-container="#app"
      position="bottom"
      :style="{ height: 'auto' }"
      v-if="showPerson"
    >
      <pickStaff @close="handleClose" :checkNodes.sync="checkNodes" />
    </van-popup>
  </div>
</template>

<script>
import { getAvailableList } from "@/api/smartMeetingRoom";
import { mapActions, mapState, mapMutations } from "vuex";
import pickStaff from "./components/pickStaff.vue";
import { appointRoom } from "@/api/smartMeetingRoom";
import { Toast } from "vant";
import Cookies from "js-cookie";
import TopWrapper from "@/components/topWrapper/index.vue";

export default {
  data() {
    return {
      date: "",
      showCalendar: false,
      meetingList: [],
      show: false,
      showPerson: false,
      reserve: {},
      checkTimeOld: [],

      checkTime: [],
      checkNodes: [],

      form: {
        mrSubject: "",
        mrDesc: "",
        isNotify: "1",
      },
    };
  },

  components: {
    pickStaff,
    TopWrapper
  },

  computed: {
    ...mapState("orderMeetingRoom", ["checkList"]),
  },
  filters: {
    formatCheck(val) {
      let arr = val.map((item) => {
        return item.departmentName;
      });
      return arr.join(",");
    },
    formatCheckTime(val, reserve) {
      if (!val.length) {
        return "无";
      }
      let timeObj = {};
      let timeDetail = reserve.timeDetail;
      for (let i in timeDetail) {
        timeObj[timeDetail[i].index] = timeDetail[i];
      }
      let length = val.length;
      return `${timeObj[val[0]].beginTime}-${timeObj[val[length - 1]].time}`;
    },
  },
  mounted() {
    const date = new Date();
    this.formatDate(date);
    this.getMeeting();
  },

  methods: {
    formatDate(date) {
      let year = date.getFullYear();
      let month = String(date.getMonth() + 1).padStart(2, "0");
      let day = String(date.getDate()).padStart(2, "0");
      this.date = `${year}-${month}-${day}`;
    },
    onConfirm(date) {
      this.showCalendar = false;
      this.formatDate(date);
      this.getMeeting();
    },
    // 获取会议室列表
    getMeeting() {
      getAvailableList({
        date: this.date,
      }).then((res) => {
        this.meetingList = res.data;
      });
    },
    handelReservation(item, index, sub, sunIndex) {
      if (sub.status == 0) {
        Toast("已过期");
      } else if (sub.status == 2) {
        Toast(`已被${sub.userName || "-"}预定`);
      } else if (sub.status == 3) {
        Toast(`已被${sub.userName || "-"}预定`);
      } else {
        this.showDialog(item, index);
      }
    },
    showDialog(item, index) {
      this.show = true;
      this.reserve = item;

      this.checkTime = [];
      let staffCode = localStorage.getItem("staffCode_H5");
      let username = localStorage.getItem("username_H5");
      this.checkNodes = [
        {
          departmentCode: staffCode,
          departmentName: username,
          type: 1,
        },
      ];
      this.form = {
        mrSubject: "",
        mrDesc: "",
        isNotify: "1",
      };
    },

    goPickStaff() {
      this.showPerson = true;
    },

    changeCheck(val) {
      let newval = val.sort((a, b) => {
        return a - b;
      });
      let newLength = newval.length;
      let oldLength = this.checkTimeOld.length;
      if (newLength >= 2) {
        if (newLength > oldLength) {
          let checkTimeFromTo = [newval[0], newval[newLength - 1]];
          let result = [];
          for (let i = checkTimeFromTo[0]; i < checkTimeFromTo[1] + 1; i++) {
            result.push(i);
          }
          this.checkTime = result;
        } else {
          let filterArr = [];
          for (let i in newval) {
            filterArr.push(newval[i]);
            if (!newval[+i + 1]) {
              break;
            }
            if (newval[+i + 1] - newval[i] > 1) {
              break;
            }
          }
          this.checkTime = filterArr;
        }
      }
      this.checkTimeOld = this.checkTime;
    },

    handleClose() {
      this.showPerson = false;
    },

    submit() {
      if (!this.form.mrSubject) {
        Toast("会议主题不能为空");
        return;
      }
      if (!this.checkNodes.length) {
        Toast("参会人员不能为空");
        return;
      }
      if (!this.checkTime.length) {
        Toast("预约时间不能为空");
        return;
      }
      let beginTime = this.checkTime[0];
      let timeLength = this.checkTime.length;
      let endTime = this.checkTime[timeLength - 1];
      let appoParts = this.checkNodes.map((item) => {
        return {
          partCode: item.departmentCode,
          type: item.type,
        };
      });
      let params = {
        appoDate: this.date,
        mrCode: this.reserve.mrCode,
        appoParts,
        beginTime,
        endTime,
        ...this.form,
      };
      appointRoom(params).then((res) => {
        Toast("预约成功");
        this.show = false;
        this.getMeeting();
      });
    },

    cancel() {
      this.show = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.order-metting-ing {
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 0.2rem;
  position: relative;
  .search-date {
    width: 100%;
    .label {
      height: 0.4rem;
      font-size: 0.28rem;
      font-weight: 400;
      color: #333333;
      line-height: 0.4rem;
    }
    .picker-box {
      border-bottom: 0.01rem solid #e5e5e5;
      padding: 0.14rem 0rem;
      .van-cell {
        padding: 0.14rem 0rem;
      }
      .van-cell__value {
        margin-left: 0.05rem;
        span {
          font-size: 0.3rem;
        }
      }
    }
  }
  .main-panel {
    padding: 0.3rem 0rem;
    width: 100%;
    .label {
      height: 0.4rem;
      font-size: 0.28rem;
      font-weight: 400;
      color: #333333;
      line-height: 0.4rem;
    }
    .list-box {
      width: 100%;
      margin-top: 0.3rem;
      .card-item {
        height: 2.66rem;
        background: #ffffff;
        border-radius: 0.2rem;
        border: 0.01rem solid #0094ee;
        overflow: hidden;
        padding: 0.2rem;
        margin-bottom: 0.2rem;
        .name {
          font-size: 0.3rem;
          font-weight: 600;
          color: #333333;
          line-height: 0.42rem;
          margin-bottom: 0.12rem;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .equipments {
          font-size: 0.26rem;
          font-weight: 400;
          color: #999999;
          line-height: 0.37rem;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-bottom: 0.12rem;
        }
        .area-nums {
          display: flex;
          flex-direction: row;
          width: 100%;
          font-size: 0.26rem;
          .area {
            font-weight: 400;
            color: #333333;
            line-height: 0.37rem;
          }
          .num {
            line-height: 0.37rem;
            color: #999;
          }
        }

        .bar-ul {
          display: grid;
          grid-template-columns: repeat(32, 3.1%);
          overflow: visible;
          .bar-li {
            width: 100%;
            flex: 1;
            overflow: visible;
            .bar {
              width: 100%;
              height: 0.32rem;
              background: #f1f5f6;
            }
            .bar.gray {
              background: #eaeaea;
            }
            .bar.darkGray {
              background: #aaa;
            }
            .bar.blue {
              background: #0094ee;
            }
            .num {
              font-size: 0.22rem;
              color: #999999;
              line-height: 0.3rem;
              height: 0.3rem;
              overflow: visible;
              white-space: nowrap;
            }
          }
          .bar-li:nth-of-type(1) {
            .bar {
              border-radius: 0.16rem 0 0 0.16rem;
            }
          }
          .bar-li:nth-last-of-type(1) {
            .bar {
              border-radius: 0 0.16rem 0.16rem 0;
            }
            .num {
              position: relative;
              left: 0.1rem;
            }
          }
        }
      }
    }
  }
}
.container {
  padding: 0.2rem 0.2rem 0 0.2rem;
  .title {
    height: 0.37rem;
    font-size: 0.3rem;
    color: #333333;
    line-height: 0.37rem;
    text-align: center;
    margin-bottom: 0.3rem;
    font-weight: bold;
  }
  .item {
    border-bottom: 1px solid #e5e5e5;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0.1rem 0;
    .sub-title {
      height: 0.37rem;
      font-size: 0.26rem;
      color: #333333;
      line-height: 0.37rem;
      margin-bottom: 0.1rem;
      .red {
        color: red;
      }
    }
    .input {
      height: 0.42rem;
      font-size: 0.3rem;
      color: #999999;
      line-height: 0.42rem;
    }
    .text {
      min-height: 0.42rem;
      font-size: 0.3rem;
      color: #999999;
      line-height: 0.42rem;
    }
  }
  .time {
    .checkbox-group {
      height: 5rem;
      overflow-y: scroll;
      .checkbox {
        height: 0.8rem;
        display: flex;
        align-items: center;
        border-top: 1px solid #f0f0f0;
        .check {
          flex: 1;
        }
        .tip {
          font-size: 0.24rem;
          color: #999;
        }
      }
    }
  }

  .bottom-buttons {
    background: #fff;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0.1rem;
    .button {
      width: 2.4rem;
      height: 0.8rem;
      border-radius: 0.4rem;
      font-size: 0.26rem;
      line-height: 0.37rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .cancel {
      background: rgba(0, 148, 238, 0.1);
      color: #0094ee;
      margin-right: 0.4rem;
    }
    .primary {
      background: #0094ee;
      color: #ffffff;
    }
  }
}
</style>
